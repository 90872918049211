import { round, sumBy } from "lodash";
import { uuidv4 } from "services/uuid";
import { ConclusionLots, INouvelleVariante } from "types";

export function calculer_conclusion(variante: INouvelleVariante) {
  const clef_de_ponderation =
    variante.ponderation_lots === "PRIX_DE_REVENTE"
      ? "prix_revente"
      : "surface";
  const charges = Object.values(variante.charges);
  charges.push({
    id: uuidv4(),
    ordre: charges.length + 1,
    designation: "Prix vendeur",
    HT: variante.prix_vendeur_HT,
    taux_TVA: variante.prix_vendeur_taux_TVA,
    TVA: variante.prix_vendeur_TVA,
    TTC: variante.prix_vendeur_TTC,
    validee: true,
    lots: Object.keys(variante.lots).reduce((acc, lotId) => {
      acc[lotId] = true;
      return acc;
    }, {} as { [key: string]: boolean }),
  });
  charges.push({
    id: uuidv4(),
    ordre: charges.length + 1,
    designation: "Frais de notaire",
    HT: variante.frais_notaire_HT,
    taux_TVA: variante.frais_notaire_taux_TVA,
    TVA: variante.frais_notaire_TVA,
    TTC: variante.frais_notaire_TTC,
    validee: true,
    lots: Object.keys(variante.lots).reduce((acc, lotId) => {
      acc[lotId] = true;
      return acc;
    }, {} as { [key: string]: boolean }),
  });
  charges.push({
    id: uuidv4(),
    ordre: charges.length + 1,
    designation: "Frais d'agence",
    HT: variante.frais_agence_HT,
    taux_TVA: variante.frais_agence_taux_TVA,
    TVA: variante.frais_agence_TVA,
    TTC: variante.frais_agence_TTC,
    validee: true,
    lots: Object.keys(variante.lots).reduce((acc, lotId) => {
      acc[lotId] = true;
      return acc;
    }, {} as { [key: string]: boolean }),
  });

  const ponderations_par_charge: {
    [chargeId: string]: { [lotId: string]: number };
  } = {};

  for (let i = 0; i < charges.length; i++) {
    const charge = charges[i];
    let total_ponderation_lot = 0;
    let nb_lots = 0;
    for (const [id_lot, a_prendre_en_compte] of Object.entries(charge.lots)) {
      if (a_prendre_en_compte) {
        nb_lots += 1;
        total_ponderation_lot += variante.lots[id_lot][clef_de_ponderation];
      }
    }
    const ponderation_lots: { [key: string]: number } = {};
    for (const [id_lot, a_prendre_en_compte] of Object.entries(charge.lots)) {
      const lot = variante.lots[id_lot];
      if (!a_prendre_en_compte) {
        ponderation_lots[id_lot] = 0;
        continue;
      }

      if (total_ponderation_lot === 0) {
        ponderation_lots[id_lot] = 1 / nb_lots;
        continue;
      }

      if (a_prendre_en_compte) {
        ponderation_lots[id_lot] =
          lot[clef_de_ponderation] / total_ponderation_lot;
      }
    }
    ponderations_par_charge[charge.id] = ponderation_lots;
  }

  const total_clef_repartition = sumBy(
    Object.values(variante.lots),
    (lot) => lot[clef_de_ponderation]
  );
  let cout_revient_TVA = variante.prix_vendeur_TTC + variante.frais_notaire_TTC;
  if (variante.frais_agence_charge === "VENDEUR") {
    cout_revient_TVA += variante.frais_agence_TTC;
  }
  let chiffre_affaire = 0;
  const lots: ConclusionLots = {};
  let TVA = 0;
  for (let i = 0; i < Object.values(variante.lots).length; i++) {
    const lot = Object.values(variante.lots)[i];
    let TVA_deductible = 0;
    for (let j = 0; j < charges.length; j++) {
      const charge = charges[j];
      const ponderation_charge = ponderations_par_charge[charge.id][lot.id];
      TVA_deductible += charge.TVA * ponderation_charge;
    }
    const TVA_collectee = round(
      (lot.prix_revente * (lot.pourcentage_TVA / 100)) /
        (1 + lot.pourcentage_TVA / 100),
      2
    );
    TVA_deductible = round(TVA_deductible, 2);
    const cout_revient_fiscal =
      total_clef_repartition === 0
        ? 0
        : (cout_revient_TVA * lot[clef_de_ponderation]) /
          total_clef_repartition;
    const prix_revente = lot.prix_revente;
    chiffre_affaire += prix_revente;
    const marge_fiscale = prix_revente - cout_revient_fiscal;
    const marge_fiscale_TVA = marge_fiscale / (1 + lot.pourcentage_TVA / 100);
    const TVA_sur_marge = (marge_fiscale_TVA * lot.pourcentage_TVA) / 100;

    let TVA_lot = 0;
    if (lot.type_TVA === "SUR_MARGE") {
      if (TVA_sur_marge - TVA_deductible > 0) {
        TVA_lot = TVA_sur_marge - TVA_deductible;
      } else {
        TVA_lot -= TVA_deductible;
      }
    }
    if (lot.type_TVA === "TOTALE") {
      TVA_lot = TVA_collectee - TVA_deductible;
    }
    TVA += TVA_lot;
    const cout_total_lot = TVA_lot + cout_revient_fiscal + TVA_deductible;
    const marge_lot = prix_revente - cout_total_lot;
    lots[lot.id] = {
      designation: lot.designation,
      TVA_deductible: TVA_deductible,
      TVA_collectee: TVA_collectee,
      prix_m2: lot.surface === 0 ? 0 : lot.prix_revente / lot.surface,
      cout_revient_fiscal: round(cout_revient_fiscal, 2),
      marge_fiscale: round(marge_fiscale, 2),
      marge_fiscale_TVA: round(marge_fiscale_TVA, 2),
      TVA_sur_marge: round(TVA_sur_marge, 2),
      TVA_lot: round(TVA_lot, 2),
      cout_total_lot: round(cout_total_lot, 2),
      marge_lot: round(marge_lot, 2),
      pourcentage_marge_lot:
        prix_revente === 0 ? 0 : round((100 * marge_lot) / prix_revente, 2),
    };
  }

  let cout_revient_operationnel = 0;
  for (let i = 0; i < charges.length; i++) {
    const charge = charges[i];
    cout_revient_operationnel += charge.TTC;
  }

  const commission_engagement = round(
    (((cout_revient_operationnel - variante.apport) *
      (variante.taux_commission_engagement / 100)) /
      12) *
      variante.duree_commission_engagement,
    2
  );
  const frais_de_dossier = variante.frais_de_dossier;
  const interet_emprunt = round(
    (((cout_revient_operationnel - variante.apport) *
      (variante.taux_interet_emprunt / 100)) /
      12) *
      variante.duree_interet_emprunt,
    2
  );
  const interet_apport = round(
    ((variante.apport * variante.taux_interet_apport) / 100 / 12) *
      variante.duree_pret_apport,
    2
  );
  const hypotheque = round(
    ((cout_revient_operationnel - variante.apport) * variante.taux_hypotheque) /
      100,
    2
  );
  const cout_levee_de_fond = variante.cout_levee_de_fond;
  const total_frais_bancaire =
    commission_engagement +
    frais_de_dossier +
    interet_emprunt +
    interet_apport +
    cout_levee_de_fond +
    hypotheque;
  const frais_bancaires = {
    total: round(total_frais_bancaire, 2),
    commission_engagement,
    frais_de_dossier,
    interet_emprunt,
    interet_apport,
    hypotheque,
    cout_levee_de_fond,
  };
  const cout_total = cout_revient_operationnel + total_frais_bancaire;
  const marge_avant_TVA = chiffre_affaire - cout_total;
  const benefice_avant_impot = marge_avant_TVA - TVA;
  const rentabilite_brute =
    cout_total === 0 ? 0 : (100 * benefice_avant_impot) / cout_total;
  const rentabilite_brute_sur_ca =
    chiffre_affaire === 0 ? 0 : (100 * benefice_avant_impot) / chiffre_affaire;
  const duree_projet = variante.duree_projet;
  const rentabilite_annuelle =
    duree_projet === 0 ? 0 : (12 * rentabilite_brute) / duree_projet;
  const rentabilite_brute_sur_ca_annuelle =
    duree_projet === 0 ? 0 : (12 * rentabilite_brute_sur_ca) / duree_projet;
  return {
    chiffre_affaire: round(chiffre_affaire, 2),
    cout_total: round(cout_total, 2),
    marge_avant_TVA: round(marge_avant_TVA, 2),
    TVA: round(TVA, 2),
    benefice_avant_impot: round(benefice_avant_impot, 2),
    rentabilite_brute: round(rentabilite_brute, 2),
    rentabilite_annuelle: round(rentabilite_annuelle, 2),
    lots: lots,
    cout_revient_operationnel: cout_revient_operationnel,
    frais_bancaires: frais_bancaires,
    rentabilite_brute_sur_ca: round(rentabilite_brute_sur_ca, 2),
    rentabilite_brute_sur_ca_annuelle: round(
      rentabilite_brute_sur_ca_annuelle,
      2
    ),
  };
}
