import { Fragment, useEffect, useRef, useState, useMemo } from "react";
import {
  FieldArrayWithId,
  FormProvider,
  UseFormReturn,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { ICharge, ILot, INouvelleVariante, VarianteForm } from "types";
import { round } from "lodash";
import {
  chaineVersMasqueEuro,
  chaineVersMasqueSurface,
} from "services/transform";
import { Select } from "catalyst/select";
import { Input } from "catalyst/input";
import { Textarea } from "catalyst/textarea";
import { Field, Fieldset, Label, Legend } from "catalyst/fieldset";
import {
  HomeModernIcon,
  BanknotesIcon,
  RectangleStackIcon,
  CreditCardIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  LockOpenIcon,
  LockClosedIcon,
  PencilIcon,
  XMarkIcon,
  InformationCircleIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { Sections, Section } from "components/Sections";
import { Button } from "catalyst/button";
import { uuidv4 } from "services/uuid";
import styled from "styled-components";
import { CheckboxField } from "catalyst/checkbox";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
  DropdownSeparator,
} from "catalyst/dropdown";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkGemoji from "remark-gemoji";
import Conclusion from "./Conclusion";
import Resultats from "./Resultats";
import InputNumber from "components/form/InputNumber";
import { recalculer_variante } from "core/variante";

function NotesForm() {
  const methods = useFormContext<VarianteForm>();
  const [isOnEditMode, setIsOnEditMode] = useState(false);
  const notes = useWatch({
    control: methods.control,
    name: "notes",
  });

  return (
    <div>
      {isOnEditMode ? (
        <div className="grid grid-cols-1 gap-4">
          <Fieldset>
            <Field>
              <Textarea rows={6} autoFocus {...methods.register("notes")} />
            </Field>
          </Fieldset>
          <div className="flex gap-2 justify-end">
            <Button plain onClick={() => setIsOnEditMode(false)}>
              <XMarkIcon />
            </Button>
            <Button
              type="submit"
              color="white"
              disabled={!methods.formState.isDirty}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 3v18h18V6l-3-3H3ZM7.5 3v6h9V3M6 21v-9h12v9M14.25 5.25v1.5"
                />
              </svg>
              Enregistrer
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={`pointer min-h-40 ${notes === "" ? "border border-zinc-950/5 rounded-md" : ""}`}
          onClick={() => setIsOnEditMode(true)}
        >
          <Markdown
            remarkPlugins={[remarkGfm, remarkGemoji]}
            className="prose-sm"
          >
            {notes}
          </Markdown>
        </div>
      )}
    </div>
  );
}

function AcquisitionForm() {
  const methods = useFormContext<VarianteForm>();
  const est_verrouillee = useWatch({
    control: methods.control,
    name: "est_verrouillee",
  });
  return (
    <div className="grid grid-cols-1 gap-8">
      <Fieldset>
        <Legend>Prix</Legend>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4">
          <Field>
            <Label>Prix de vente initial</Label>
            <InputNumber
              control={methods.control}
              name="prix_vente_initial"
              disabled={est_verrouillee}
            />
          </Field>
          <Field>
            <Label>Prix FAI</Label>
            <InputNumber
              control={methods.control}
              name="prix_achat_FAI"
              disabled={est_verrouillee}
              className="w-full"
            />
          </Field>
          <Field>
            <Label>Prix net vendeur</Label>
            <InputNumber
              control={methods.control}
              name="prix_vendeur_TTC"
              disabled={est_verrouillee}
              className="w-full"
            />
          </Field>
          <Field>
            <Label>Taux TVA</Label>
            <InputNumber
              control={methods.control}
              name="prix_vendeur_taux_TVA"
              disabled={est_verrouillee}
              type="percentage"
            />
          </Field>
          <Field>
            <Label>TVA</Label>
            <InputNumber
              disabled
              className="w-full"
              control={methods.control}
              name="prix_vendeur_TVA"
            />
          </Field>
          <Field>
            <Label>HT</Label>
            <InputNumber
              disabled
              className="w-full"
              control={methods.control}
              name="prix_vendeur_HT"
            />
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <Legend>Frais d'agence</Legend>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4">
          <Field>
            <Label>% frais d'agence</Label>
            <InputNumber
              control={methods.control}
              name="frais_agence_pourcentage"
              disabled={est_verrouillee}
              type="percentage"
            />
          </Field>
          <Field>
            <Label>Mandat charge</Label>
            <Select
              disabled={est_verrouillee}
              {...methods.register("frais_agence_charge")}
            >
              <option value="ACQUEREUR">Acquéreur</option>
              <option value="VENDEUR">Vendeur</option>
            </Select>
          </Field>
          <Field>
            <Label>Montant</Label>
            <InputNumber
              disabled={est_verrouillee}
              placeholder="TTC"
              control={methods.control}
              name="frais_agence_TTC"
            />
          </Field>
          <Field>
            <Label>Taux TVA</Label>
            <InputNumber
              disabled={est_verrouillee}
              type="percentage"
              control={methods.control}
              name="frais_agence_taux_TVA"
            />
          </Field>
          <Field>
            <Label>TVA</Label>
            <InputNumber
              disabled
              className="w-full"
              control={methods.control}
              name="frais_agence_TVA"
            />
          </Field>
          <Field>
            <Label>HT</Label>
            <InputNumber
              disabled
              className="w-full"
              control={methods.control}
              name="frais_agence_HT"
            />
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <Legend>Frais de notaire</Legend>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4">
          <Field>
            <Label>% frais de notaire</Label>
            <InputNumber
              disabled={est_verrouillee}
              className="w-full"
              type="percentage"
              control={methods.control}
              name="frais_notaire_pourcentage"
            />
          </Field>
          <Field>
            <Label>Montant</Label>
            <InputNumber
              disabled={est_verrouillee}
              placeholder="TTC"
              control={methods.control}
              name="frais_notaire_TTC"
            />
          </Field>
          <Field>
            <Label>Taux TVA</Label>
            <InputNumber
              disabled={est_verrouillee}
              type="percentage"
              control={methods.control}
              name="frais_notaire_taux_TVA"
            />
          </Field>
          <Field>
            <Label>TVA</Label>
            <InputNumber
              disabled
              className="w-full"
              control={methods.control}
              name="frais_notaire_TVA"
            />
          </Field>
          <Field>
            <Label>HT</Label>
            <InputNumber
              disabled
              className="w-full"
              control={methods.control}
              name="frais_notaire_HT"
            />
          </Field>
        </div>
      </Fieldset>
    </div>
  );
}

function FraisBancairesForm() {
  const methods = useFormContext<VarianteForm>();
  const est_verrouillee = useWatch({
    control: methods.control,
    name: "est_verrouillee",
  });
  return (
    <div className="grid grid-cols-1 gap-8">
      <Fieldset>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <Field>
            <Label>Durée du projet</Label>
            <InputNumber
              disabled={est_verrouillee}
              type="mois"
              control={methods.control}
              name="duree_projet"
            />
          </Field>
          <Field>
            <Label>Frais de dossier</Label>
            <InputNumber
              disabled={est_verrouillee}
              control={methods.control}
              name="frais_de_dossier"
            />
          </Field>
          <Field>
            <Label>Hypothèque</Label>
            <InputNumber
              disabled={est_verrouillee}
              type="percentage"
              control={methods.control}
              name="taux_hypotheque"
            />
          </Field>
          <Field>
            <Label>Coût levée de fond</Label>
            {/* TODO tooltip <Text>Lorsque vous levez des fonds par du crowfunding par exemple, vous pouvez avoir un coût fixe au moment de la levée de fonds.</Text> */}
            <InputNumber
              disabled={est_verrouillee}
              control={methods.control}
              name="cout_levee_de_fond"
            />
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <Field>
            <Label>Commission d'engagement</Label>
            <InputNumber
              disabled={est_verrouillee}
              type="percentage"
              className="mb-2"
              control={methods.control}
              name="taux_commission_engagement"
            />
            <InputNumber
              disabled={est_verrouillee}
              type="mois"
              control={methods.control}
              name="duree_commission_engagement"
            />
          </Field>
          <Field>
            <Label>Intérêt d'emprunt</Label>
            <InputNumber
              disabled={est_verrouillee}
              type="percentage"
              className="mb-2"
              control={methods.control}
              name="taux_interet_emprunt"
            />
            <InputNumber
              disabled={est_verrouillee}
              type="mois"
              control={methods.control}
              name="duree_interet_emprunt"
            />
          </Field>
          <Field>
            <Label>Apport</Label>
            <InputNumber
              disabled={est_verrouillee}
              className="mb-2"
              control={methods.control}
              name="apport"
            />
            <InputNumber
              disabled={est_verrouillee}
              type="percentage"
              control={methods.control}
              name="pourcentage_apport"
            />
          </Field>
          <Field>
            <Label>Coût de l'apport</Label>
            <InputNumber
              disabled={est_verrouillee}
              className="mb-2"
              type="percentage"
              control={methods.control}
              name="taux_interet_apport"
            />
            <InputNumber
              disabled={est_verrouillee}
              type="mois"
              control={methods.control}
              name="duree_pret_apport"
            />
          </Field>
        </div>
      </Fieldset>
    </div>
  );
}

const LotsDiv = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (min-width: 640px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(7, minmax(0, 1fr)) auto;
    gap: 0;
    column-gap: 1rem;
  }
`;

function LotsForm() {
  const methods = useFormContext<VarianteForm>();
  const {
    fields: lots,
    append,
    remove,
  } = useFieldArray({
    control: methods.control,
    name: "lots",
  });
  const est_verrouillee = useWatch({
    control: methods.control,
    name: "est_verrouillee",
  });

  const total_prix_revente = Object.values(lots).reduce((acc, lot) => {
    acc += lot.prix_revente;
    return acc;
  }, 0);
  const total_surface = Object.values(lots).reduce((acc, lot) => {
    acc += lot.surface;
    return acc;
  }, 0);
  const total_prix_m2 =
    total_surface === 0 ? 0 : round(total_prix_revente / total_surface, 2);

  return (
    <div className="flex flex-col gap-4">
      <Fieldset>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <Field className="col-start-1">
            <Label>Répartition fiscale des lots</Label>
            <Select
              disabled={est_verrouillee}
              {...methods.register("ponderation_lots")}
            >
              <option value="PRIX_DE_REVENTE">Prix de revente</option>
              <option value="COMMERCIALE">Surface</option>
            </Select>
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <LotsDiv>
          {lots.map((lot, index) => (
            <Fragment key={lot.id}>
              <Field className="col-start-1 col-span-2">
                <Label className={index === 0 ? "" : "lg:hidden"}>
                  Designation
                </Label>
                <Input
                  disabled={est_verrouillee}
                  {...methods.register(`lots.${index}.designation`)}
                />
              </Field>
              <Field>
                <Label className={index === 0 ? "" : "lg:hidden"}>
                  Prix de revente
                </Label>
                <InputNumber
                  disabled={est_verrouillee}
                  control={methods.control}
                  name={`lots.${index}.prix_revente`}
                />
              </Field>
              <Field>
                <Label className={index === 0 ? "" : "lg:hidden"}>
                  Surface
                </Label>
                <InputNumber
                  disabled={est_verrouillee}
                  type="surface"
                  control={methods.control}
                  name={`lots.${index}.surface`}
                />
              </Field>
              <Field>
                <Label className={index === 0 ? "" : "lg:hidden"}>
                  Prix au m²
                </Label>
                <Input
                  value={`${chaineVersMasqueEuro(
                    lot.surface === 0
                      ? 0
                      : round(lot.prix_revente / lot.surface, 2)
                  )} / m²`}
                  disabled
                />
              </Field>
              <Field>
                <Label className={index === 0 ? "" : "lg:hidden"}>% TVA</Label>
                <InputNumber
                  disabled={est_verrouillee}
                  type="percentage"
                  control={methods.control}
                  name={`lots.${index}.pourcentage_TVA`}
                />
              </Field>
              <Field>
                <Label className={index === 0 ? "" : "lg:hidden"}>
                  Type TVA
                </Label>
                <Select
                  disabled={est_verrouillee}
                  {...methods.register(`lots.${index}.type_TVA`)}
                >
                  <option value="TOTALE">TVA sur le total</option>
                  <option value="SUR_MARGE">TVA sur la marge</option>
                  <option value="EXONEREE">Exonéré</option>
                </Select>
              </Field>
              <div className="flex items-end">
                <Dropdown>
                  <DropdownButton plain aria-label="Plus d'options">
                    <EllipsisVerticalIcon />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem
                      disabled={est_verrouillee}
                      onClick={() => remove(index)}
                    >
                      <TrashIcon className="stroke-red-500" />
                      <DropdownLabel className="text-red-500">
                        Supprimer
                      </DropdownLabel>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Fragment>
          ))}
          <>
            <div className="col-start-1 col-span-2">
              <Button
                color="white"
                className="mt-3"
                onClick={() => {
                  const ordre = lots.length + 1;
                  append({
                    id: uuidv4(),
                    ordre,
                    designation: `Lot ${ordre}`,
                    type_TVA: "TOTALE",
                    pourcentage_TVA: 20,
                    surface: 0,
                    prix_revente: 0,
                  });
                }}
              >
                <PlusIcon className="stroke-gray-900" />
                Ajouter un lot
              </Button>
            </div>
            <Field>
              <Label className="lg:hidden">Total prix de revente</Label>
              <Input
                value={chaineVersMasqueEuro(total_prix_revente)}
                disabled
              />
            </Field>
            <Field>
              <Label className="lg:hidden">Total surface</Label>
              <Input
                value={`${chaineVersMasqueSurface(total_surface)}`}
                disabled
              />
            </Field>
            <Field>
              <Label className="lg:hidden">Total prix au m²</Label>
              <Input
                value={`Prix moyen: ${chaineVersMasqueEuro(
                  total_prix_m2
                )} / m²`}
                disabled
              />
            </Field>
          </>
        </LotsDiv>
      </Fieldset>
    </div>
  );
}

function Charge({
  lots,
  charge,
  index,
  methods,
  remove,
}: {
  lots: ILot[];
  index: number;
  methods: UseFormReturn<VarianteForm, any, undefined>;
  charge: FieldArrayWithId<VarianteForm, "charges", "id">;
  remove: (index: number) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const isValidated = useWatch({ name: `charges.${index}.validee` });
  const est_verrouillee = useWatch({
    control: methods.control,
    name: "est_verrouillee",
  });
  return (
    <>
      <Field className="col-start-1 col-span-2">
        <Label className={index === 0 ? "" : "lg:hidden"}>Designation</Label>
        <Input
          key={charge.id}
          {...methods.register(`charges.${index}.designation`)}
          disabled={isValidated || est_verrouillee}
        />
      </Field>
      <Field>
        <Label className={index === 0 ? "" : "lg:hidden"}>TTC</Label>
        <InputNumber
          disabled={isValidated || est_verrouillee}
          control={methods.control}
          name={`charges.${index}.TTC`}
        />
      </Field>
      <Field>
        <Label className={index === 0 ? "" : "lg:hidden"}>HT</Label>
        <InputNumber
          disabled
          control={methods.control}
          name={`charges.${index}.HT`}
        />
      </Field>
      <Field>
        <Label className={index === 0 ? "" : "lg:hidden"}>Taux TVA</Label>
        <InputNumber
          type="percentage"
          disabled={isValidated || est_verrouillee}
          control={methods.control}
          name={`charges.${index}.taux_TVA`}
        />
      </Field>
      <Field>
        <Label className={index === 0 ? "" : "lg:hidden"}>TVA</Label>
        <InputNumber
          disabled
          control={methods.control}
          name={`charges.${index}.TVA`}
        />
      </Field>
      <div className="flex items-end">
        <Button
          outline
          onClick={() => setIsOpen(!isOpen)}
          className="whitespace-nowrap w-full"
          disabled={isValidated || est_verrouillee}
        >
          Affecter les lots
        </Button>
      </div>
      <div className="flex items-end">
        <Dropdown>
          <DropdownButton plain aria-label="Plus d'options">
            <EllipsisVerticalIcon />
          </DropdownButton>
          <DropdownMenu>
            <DropdownItem
              disabled={est_verrouillee}
              onClick={() =>
                methods.setValue(`charges.${index}.validee`, !isValidated)
              }
            >
              {isValidated ? <LockOpenIcon /> : <LockClosedIcon />}
              <DropdownLabel>
                {isValidated ? "Dévalider" : "Valider"}
              </DropdownLabel>
            </DropdownItem>
            <DropdownSeparator />
            <DropdownItem
              onClick={() => remove(index)}
              disabled={isValidated || est_verrouillee}
            >
              <TrashIcon className="stroke-red-500" />
              <DropdownLabel className="text-red-500">Supprimer</DropdownLabel>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      {isOpen && (
        <div className="col-span-full	grid grid-flow-row-dense grid-cols-3 gap-4 p-4">
          {lots.map((lot) => (
            <CheckboxField>
              <input
                disabled={isValidated || est_verrouillee}
                type="checkbox"
                {...methods.register(`charges.${index}.lots.${lot.id}`)}
              />
              <Label>{lot.designation}</Label>
            </CheckboxField>
          ))}
        </div>
      )}
    </>
  );
}

const ChargesDiv = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (min-width: 640px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(7, minmax(0, 1fr)) auto;
    gap: 0;
    column-gap: 1rem;
  }
`;

function ChargesForm() {
  const methods = useFormContext<VarianteForm>();
  const {
    fields: charges,
    append,
    remove,
  } = useFieldArray({
    control: methods.control,
    name: "charges",
  });
  const lots = methods.getValues().lots;
  return (
    <div className="flex flex-col gap-4">
      <Fieldset>
        <ChargesDiv>
          {charges.map((charge, index) => (
            <Charge
              key={charge.id}
              lots={lots}
              index={index}
              charge={charge}
              methods={methods}
              remove={remove}
            />
          ))}
        </ChargesDiv>
      </Fieldset>
      <div>
        <Button
          color="white"
          onClick={() => {
            const ordre = charges.length + 1;
            const id = uuidv4();
            const charge = {
              id,
              ordre,
              designation: `Charge ${ordre}`,
              HT: 0,
              taux_TVA: 20,
              TVA: 0,
              TTC: 0,
              validee: false,
              lots: lots.reduce(
                (acc, lot) => {
                  acc[lot.id] = true;
                  return acc;
                },
                {} as { [x: string]: boolean }
              ),
            };
            append(charge);
          }}
        >
          <PlusIcon className="stroke-gray-900" />
          Ajouter une charge
        </Button>
      </div>
    </div>
  );
}

function varianteToVarianteForm<T extends INouvelleVariante>(
  variante: T
): VarianteForm {
  return {
    ...variante,
    lots: Object.values(variante.lots),
    charges: Object.values(variante.charges),
  };
}

function varianteFormToVariante<T extends INouvelleVariante>(
  varianteForm: VarianteForm
): T {
  const lotsById = varianteForm.lots.reduce(
    (acc, lot) => {
      acc[lot.id] = lot;
      return acc;
    },
    {} as { [k: string]: ILot }
  );
  const chargesById = varianteForm.charges.reduce(
    (acc, charge) => {
      acc[charge.id] = charge;
      return acc;
    },
    {} as { [k: string]: ICharge }
  );
  return {
    ...varianteForm,
    lots: lotsById,
    charges: chargesById,
  } as T;
}

interface VarianteProps<T> {
  id?: string;
  variante: T;
  onSubmit: (variante: T) => void;
}

export default function Variante<T extends INouvelleVariante>({
  id = "variante-form",
  variante,
  onSubmit,
}: VarianteProps<T>) {
  const methods = useForm<VarianteForm>({
    defaultValues: useMemo(() => varianteToVarianteForm(variante), [variante]),
  });

  const dirty = useRef(false);

  dirty.current = methods.formState.isDirty;

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (dirty.current) {
        e.preventDefault();
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    methods.reset(varianteToVarianteForm(variante));
  }, [variante]);

  return (
    <FormProvider {...methods}>
      <form
        id={id}
        onSubmit={methods.handleSubmit((varianteForm: VarianteForm) => {
          const nouvelleVariante = varianteFormToVariante(varianteForm);
          const varianteUpdated = recalculer_variante(
            variante,
            nouvelleVariante
          );
          onSubmit(varianteUpdated as T);
        })}
      >
        <div className="block lg:sticky top-16 z-10">
          <Conclusion variante={variante} />
        </div>
        <Sections>
          <Section title="Notes" icon={<PencilIcon className="h-5 w-5 mr-5" />}>
            <NotesForm />
          </Section>
          <Section
            title="Acquisition"
            icon={<HomeModernIcon className="h-5 w-5 mr-5" />}
            defaultIsOpen
          >
            <AcquisitionForm />
          </Section>
          <Section
            title="Frais bancaires / Apport"
            icon={<BanknotesIcon className="h-5 w-5 mr-5" />}
            defaultIsOpen
          >
            <FraisBancairesForm />
          </Section>
          <Section
            title="Lots"
            icon={<RectangleStackIcon className="h-5 w-5 mr-5" />}
          >
            <LotsForm />
          </Section>
          <Section
            title="Charges"
            icon={<CreditCardIcon className="h-5 w-5 mr-5" />}
          >
            <ChargesForm />
          </Section>
          <Section
            title="Résultats"
            icon={<InformationCircleIcon className="h-5 w-5 mr-5" />}
          >
            <Resultats variante={variante} />
          </Section>
        </Sections>
      </form>
    </FormProvider>
  );
}
